import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"

const CHPCalc = () => {
  const externalCosts = [
    {
      label: "Advertising and Marketing*",
      id: "input1",
    },
    {
      label: "Pre-hire Health Screens*",
      id: "input2",
    },
    {
      label: "Background Checks and Eligibility to Work*",
      id: "input3",
    },
    {
      label: "Pre-screening Fees*",
      id: "input4",
    },
    {
      label: "Campus Recruiting*",
      id: "input5",
    },
    {
      label: "Recruitment Process Outsourcing Fees*",
      id: "input6",
    },
    {
      label: "Consulting Services*",
      id: "input7",
    },
    {
      label: "Relocation Fees*",
      id: "input8",
    },
    {
      label: "Contingency Fees (Contingent to Regular)*",
      id: "input9",
    },
    {
      label: "Sign-on Bonuses*",
      id: "input10",
    },
    {
      label: "Drug Testing*",
      id: "input11",
    },
    {
      label: "Sourcing Costs*",
      id: "input12",
    },
    {
      label: "Employee Referral Awards/Payments*",
      id: "input13",
    },
    {
      label: "Travel and Others (Candidate and Recruiter)*",
      id: "input14",
    },
    {
      label: "Immigration Payments*",
      id: "input15",
    },
    {
      label: "Technology Costs*",
      id: "input16",
    },
    {
      label: "Job Fair/Recruiting Event*",
      id: "input17",
    },
    {
      label: "Third-party Agency Fees*",
      id: "input18",
    },
  ]

  const internalCosts = [
    {
      label: "Cost of Recruiting",
      id: "input1",
    },
    {
      label: "Recruiting L&D",
      id: "input2",
    },
    {
      label: "Cost of Sourcing",
      id: "input3",
    },
    {
      label: "Secondary Management Cost of Time for Events",
      id: "input4",
    },
    {
      label: "Internal Overhead for Compliance",
      id: "input5",
    },
    {
      label: "Secondary Management Cost of Time for Recruiting",
      id: "input6",
    },
    {
      label: "Non-labor Office Costs",
      id: "input7",
    },
  ]

  const [inputExternalValues, setInputExternalValues] = useState({})
  const [inputInternalValues, setInputInternalValues] = useState({})
  const [inputNewHire, setInputNewHire] = useState(0)
  const [externalCostTotal, setExternalCostTotal] = useState(0)
  const [internalCostTotal, setInternalCostTotal] = useState(0)
  const [totalCHP, setTotalCHP] = useState(0)

  const handleExternalInputChange = e => {
    const { id, value } = e.target
    setInputExternalValues({
      ...inputExternalValues,
      [id]: parseFloat(value) || 0,
    })
  }

  const handleInternalInputChange = e => {
    const { id, value } = e.target
    setInputInternalValues({
      ...inputInternalValues,
      [id]: parseFloat(value) || 0,
    })
  }

  const handleNewHireInputChange = e => {
    const value = parseFloat(e.target.value) || 0
    setInputNewHire(value)
  }

  const calcTotalExternalCosts = () => {
    const newTotal = externalCosts.reduce((acc, cost) => {
      const inputValue = inputExternalValues[cost.id] || 0
      return acc + inputValue
    }, 0)

    setExternalCostTotal(newTotal)
  }

  const calcTotalInternalCosts = () => {
    const newTotal = internalCosts.reduce((acc, cost) => {
      const inputValue = inputInternalValues[cost.id] || 0
      return acc + inputValue
    }, 0)

    setInternalCostTotal(newTotal)
  }

  useEffect(() => {
    calcTotalExternalCosts()
    calcTotalInternalCosts()
  }, [inputExternalValues, inputInternalValues])

  const calcCHPtotal = () => {
    let total = (externalCostTotal + internalCostTotal) / inputNewHire
    setTotalCHP(total)
  }

  return (
    <div className="bg-purple-light w-full py-10 md:py-20 px-5 md:px-0">
      <div className="w-full text-center">
        <p className="text-orangeText text-base">
          ***This is an extensive calculator. Fill up the fields that is
          relevant for you. If not relevant, keep the expenses marked as
          <span className="font-medium"> ZERO</span>.
        </p>
      </div>
      <div className="max-w-7xl mx-auto bg-white px-12 rounded-lg py-10 shadow-md">
        <div className="text-3xl  font-medium">External Costs</div>
        <div className="mt-5 space-y-4">
          <ol className="p-0 flex flex-wrap">
            {externalCosts.map((item, index) => (
              <li
                key={index}
                className={`p-0 flex w-full md:w-1/2 ${
                  index % 2 === 0 ? "justify-start" : "justify-end"
                }`}
              >
                <div className="flex  w-full md:w-11/12 justify-between ">
                  <label htmlFor={item.id}>{`${index + 1}. ${
                    item.label
                  }`}</label>
                  <input
                    type="number"
                    id={item.id}
                    className="w-32 rounded-xl"
                    onChange={handleExternalInputChange}
                  />
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="max-w-7xl mx-auto bg-white px-12 rounded-lg py-10 shadow-md mt-5">
        <div className="text-3xl  font-medium">Internal Costs</div>
        <div className="mt-5 space-y-4">
          <ol className="p-0 flex flex-wrap">
            {internalCosts.map((item, index) => (
              <li
                key={index}
                className={`p-0 flex w-full md:w-1/2 ${
                  index % 2 === 0 ? "justify-start" : "justify-end"
                }`}
              >
                <div className="flex  w-full  md:w-11/12 justify-between ">
                  <label htmlFor={item.id}>{`${index + 1}. ${
                    item.label
                  }`}</label>
                  <input
                    type="number"
                    id={item.id}
                    className="w-32 rounded-xl"
                    onChange={handleInternalInputChange}
                  />
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="max-w-7xl mx-auto bg-white px-12 rounded-lg py-10 shadow-md mt-5">
        <div className="text-3xl font-medium">Number Of Hire</div>
        <div className="mt-5 space-y-4">
          <ol className="p-0 flex flex-wrap">
            <li className="p-0 flex w-full justify-between">
              <label htmlFor="" className="w-full md:w-1/2 ">
                Total Number of New Hiring in a Specific Time Period.
              </label>
              <input
                type="number"
                id=""
                className="w-32 md:w-1/4 rounded-xl"
                onChange={handleNewHireInputChange}
              />
            </li>
          </ol>
        </div>
      </div>
      <div className="max-w-7xl mx-auto bg-purple-light  py-10  mt-5">
        <div className="flex flex-col md:flex-row items-center md:justify-between">
          <button
            className="bg-indigo-100 text-white text-sm px-6 py-4 rounded-md"
            onClick={calcCHPtotal}
          >
            Calculate Your CPH
          </button>
          <div className="mt-10 md:mt-0">
            Your Cost-per-hire is{" "}
            <span className="bg-orange20 py-4 px-6 border border-red rounded-lg ml-4 font-bold text-2xl text-yellow300">
              ${totalCHP}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const renderCHPCalculator = () => {
  if (typeof window !== "undefined") {
    let id = document.querySelector("#CHPCalculator")
    if (id != null) {
      ReactDOM.render(<CHPCalc />, id)
    }
  }
}

setTimeout(renderCHPCalculator, 100)

export default CHPCalc
