import React, { useState } from "react";
import ReactDOM from "react-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'
import Trans from '../../components/Trans'

import PopUp from "../PopUp";

const WebcastCarouselIndia = () => {
    const [open, setOpen] = useState(false);
    const [formID, setformID] = useState(``)
    const onOpenModal = (i) => {
        setOpen(true);
        setformID(i)
    }
    const onCloseModal = () => setOpen(false);
    
    return (
<div className = "testimonial-slider container text-center px-6 py-10 md:py-28">
<h2 className = "text-2xl md:text-3xl lg:text-5xl text-gray-250">Previous <span className = "relative">Webcasts<img className = "absolute -bottom-1 right-0" src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png" /> </span></h2>
    <h2 className="section-title mb-14 md:mb-28">
        <Trans>Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!</Trans>
    </h2>
    <CarouselProvider className="max-w-5xl relative mx-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={65}
        totalSlides={3}
    >
        {/* <div className="quote-icon1 relative opacity-40 z-10">
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" />
        </div> */}
      
        <Slider className="rounded-lg shadow-xl z-0 w-full h-50">
        <Slide index={0} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 3: Creating an all-encompassing holistic Total rewards strategy can engage today's diverse and dynamic workforce.</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/11/VP-webcast-episode-3.jpeg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/11/image_2022_11_22T09_17_43_385Z.png" alt="Nabeela Ixtabalan, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Priya Chakravarty</h2>
                                <p class="text-gray-200 text-sm text-left">Head HR Excellence at Essar</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/11/image_2022_11_22T09_18_05_925Z.png" alt="Melissa Salibi, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Saumya Mondal</h2>
                                <p class="text-gray-200 text-sm text-left">Vice President Human Resource at AAK</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/11/image_2022_11_22T09_25_30_193Z.png" alt="Xavier Joly, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Sumit Neogi</h2>
                                <p class="text-gray-200 text-sm text-left">CPO, HR Director at The Lubrizol Corporation</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`5`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide>
            <Slide index={1} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 2: Can Great Recognition Replace Great Resignation?</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/Vantage-PointEP2-with-Kaustubh-Episode-1.png" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/India-Web-EP2-Guest1.png" alt="Nabeela Ixtabalan, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Saswati Sinha</h2>
                                <p class="text-gray-200 text-sm text-left">Head of Human Resources at Evalueserve</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/Prosenjit-2.png" alt="Melissa Salibi, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Prosenjit Mukherjee</h2>
                                <p class="text-gray-200 text-sm text-left">Group Head – Human Resources at SRKay Consulting Group</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`6`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide>
            <Slide index={2} className="bg-gradient-to-br from-purple-100 to-pink-130 overflow-y-scroll lg:overflow-hidden"  style = {{background: 'rgb(248,248,255)',
            background: 'linearGradient(142deg, rgba(248,248,255,1) 29%, rgba(255,255,255,1) 91%)'}}>
                <div className="mx-auto py-9 px-3 flex flex-col justify-center items-center">
                   
                   <div className = "flex justify-start w-full px-2 mt-10 mb-6"><p className = "text-left text-gray-200 font-bold  text-lg md:text-xl lg:text-2xl">Episode 1: The Metaverse’s Impact on Neurodivergence: A Blessing or a Curse for New-Age HRs?</p></div>
                   <div className = "grid lg:grid-cols-2 gap-8 justify-between items-center px-2">
                     <div className = "w-full w-1/5 flex justify-center items-center">
                        <img loading = "lazy" src = "https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/Vantage-Point-with-Kaustubh-Episode-1.jpg" />
                     </div>
                    <div className = "w-full lg:w-4/5 flex flex-col items-center">
                        <div className = "flex w-11/12 lg:justify-start mb-4 xl:mb-0 2xl:mb-1"><p className = "text-center lg:text-left text-gray-200 text-lg lg:text-xl">Guest Speakers -</p></div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center w-full md:w-5/6 lg:w-full xl:w-full pl-5 md:pl-0 lg:pl-0 xl:px-0 relative">
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/VP-IND-EP1-speaker3.png" alt="Nabeela Ixtabalan, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Dr. Tanya Mishra</h2>
                                <p class="text-gray-200 text-sm text-left">Global CHRO at Strides Pharma Lifesciences</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/VP-IND-EP1-speaker1.png" alt="Melissa Salibi, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Dr. Adil Malia</h2>
                                <p class="text-gray-200 text-sm text-left">CEO, The FiRM</p>
                            </div>
                            <div class="flex flex-col">
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2022/08/VP-IND-EP1-speaker2.png" alt="Xavier Joly, Vantage Point Webcast" width="150" height="150"/>
                                <h2 class="font-bold text-gray-250 text-lg md:text-lg xl:text-xl 2xl:text-xl py-2 text-left">Dr. Ravichandran Kanniappan</h2>
                                <p class="text-gray-200 text-sm text-left">CPO, Adrenalin eSystems Limited</p>
                            </div>
                        </div>
                        <div className = "w-full flex justify-center lg:justify-start mt-8 lg:mt-3"><div onClick = {() => onOpenModal(`5`)} className = "w-full md:w-1/2 lg:w-10/12 lg:w-5/6 xl:w-full cursor-pointer"><p className="vc-new-orange-btn">Get Webcast Recordings</p></div></div>
                    </div>
                   </div>
                </div>
            </Slide>
        </Slider>
        <ButtonBack className="shadow eb-carousel-btn p-2 rounded-full flex justify-center items-center">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
        </ButtonBack>
        <ButtonNext className="shadow eb-carousel-btn p-2 rounded-full flex justify-center items-center">
            <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
        </ButtonNext>
    </CarouselProvider>


    <section>
        <div className={open ? 'absolute top-0 left-0 h-full w-full z-50 flex' : 'hidden'}>
            <div className='fixed bg-gray-500 opacity-50 h-full w-full'></div>
        </div>

        <div className={ open ? 'w-full h-full flex justify-center items-center absolute left-0 top-0 bg-gray z-50 PopUp' : ''}>
            <PopUp condition = {open} closeModal = {onCloseModal} idForm = {formID}/>
        </div>
    </section>
</div>
    )
};


const renderWebcastCarouselIndia=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#webcastcarouselIndia")
        if (id != null){
            ReactDOM.render(
                <WebcastCarouselIndia />,
                id
            )
        }
    }
}

setTimeout(renderWebcastCarouselIndia,100)

export default WebcastCarouselIndia;